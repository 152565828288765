import { Flex, Text, useToast } from "@chakra-ui/react";
import {
  changeChromaKeyMachine,
  getDetailMachineSettings,
} from "api/machine.api";
import { postUploadImage } from "api/prize.api";
import ButtonType from "components/Button";
import ModalBase from "components/Modal/ModalBase";
import { FormFileUpload } from "components/form/FormFileUpload";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import FormSlider from "components/form/FormSlider";
import { STATUS } from "constants/constants";
import { NUMBER } from "constants/enum";
import useWithToast from "hooks/useWithToast";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { convertParams, omitParams } from "utils/object";
import { checkMaxImage } from "utils/uploadFile";
import { CHROMA_KEY_OPTIONS } from "./constants";

export default function DetailCameraModal({
  isOpen,
  onClose,
  dataDetail,
  cameraType,
}) {
  const [machineDetail, setMachineDetail] = useState();
  const [nameImage, setNameImage] = useState("");

  const { showToastSuccess } = useWithToast();
  const toast = useToast();

  const getColor = (keyColors, index) => {
    if (keyColors) {
      return `#${keyColors?.split(",")?.[index]}`;
    }
    return "#28a745";
  };

  const defaultValues = {
    image: "",
    chromaKey: "false",
    color1: "#28a745",
    color2: "#28a745",
    color3: "#28a745",
    start_coords_x: 0,
    start_coords_y: 0,
    end_coords_x: 1,
    end_coords_y: 1,
    similarity: 0,
    smoothness: 0,
    spill: 0,
  };

  const form = useForm({
    defaultValues,
  });

  const { handleSubmit, watch, reset, getValues, setValue } = form;

  const resetCam = (src) => {
    const iframe = document.getElementById("iframe-cam");
    iframe.src = src;
  };

  const removeHash = (string) => {
    return string?.replace("#", "")?.trim();
  };

  const srcCam = useCallback(
    (newParams) => {
      const color1 = removeHash(newParams?.color1);
      const color2 = removeHash(newParams?.color2);
      const color3 = removeHash(newParams?.color3);
      const similarity = Number(newParams?.similarity);
      const smoothness = Number(newParams?.smoothness);
      const spill = Number(newParams?.spill);
      const params = convertParams(
        omitParams({
          chromakey: newParams?.chromaKey,
          image: newParams?.image,
        })
      );
      return cameraType === STATUS.FRONT
        ? `${dataDetail?.web_link_cam_front}&${params}&keyColors=${color1},${color2},${color3}&similarity=${similarity}&smoothness=${smoothness}&spill=${spill}`
        : `${dataDetail?.web_link_cam_back}&${params}&keyColors=${color1},${color2},${color3}&similarity=${similarity}&smoothness=${smoothness}&spill=${spill}`;
    },
    [cameraType, dataDetail]
  );

  const getMachineDetail = async (id, cameraType) => {
    try {
      const { data } = await getDetailMachineSettings(id);
      if (data?.success) {
        const detail = data?.data;
        const itemCam = detail?.crane_machine?.crane_machine_chromakeys?.find(
          (item) => item?.cam_position === cameraType
        );
        setMachineDetail(itemCam);

        const params = {
          image: itemCam?.background_image_url || "",
          chromaKey: itemCam?.chromakey ? "true" : "false",
          color1: getColor(itemCam?.key_colors, 0),
          color2: getColor(itemCam?.key_colors, 1),
          color3: getColor(itemCam?.key_colors, 2),
          start_coords_x: itemCam?.start_coords_x,
          start_coords_y: itemCam?.start_coords_y,
          end_coords_x: itemCam?.end_coords_x,
          end_coords_y: itemCam?.end_coords_y,
          similarity: itemCam?.similarity,
          smoothness: itemCam?.smoothness,
          spill: itemCam?.spill,
        };
        reset(params);
        if (itemCam?.chromakey) {
          const cam = srcCam(params);
          resetCam(cam);
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const isCameraActive = useMemo(() => {
    if (cameraType === STATUS.FRONT) {
      return dataDetail?.status_cam_front === STATUS.OK;
    }
    return dataDetail?.status_cam_back === STATUS.OK;
  }, [dataDetail, cameraType]);

  useEffect(() => {
    if (dataDetail?.machine?.id) {
      getMachineDetail(dataDetail?.machine?.id, cameraType);
    }
  }, [dataDetail?.machine?.id, cameraType, isCameraActive]);

  const viewCam = () => {
    const data = getValues();
    const cam = srcCam(data);
    resetCam(cam);
  };

  const onSubmit = async (data) => {
    try {
      const color1 = removeHash(data?.color1);
      const color2 = removeHash(data?.color2);
      const color3 = removeHash(data?.color3);
      const dataSubmit = {
        id: machineDetail?.id,
        crane_machine_id: machineDetail?.crane_machine_id,
        cam_position: cameraType,
        chromakey: data?.chromaKey === "true",
        background_image_url: data?.image,
        key_colors: `${color1},${color2},${color3}`,
        start_coords_x: Number(data?.start_coords_x),
        start_coords_y: Number(data?.start_coords_y),
        end_coords_x: Number(data?.end_coords_x),
        end_coords_y: Number(data?.end_coords_y),
        similarity: Number(data?.similarity),
        smoothness: Number(data?.smoothness),
        spill: Number(data?.spill),
      };
      const res = await changeChromaKeyMachine(dataSubmit);
      if (res?.data?.success) {
        viewCam();
        showToastSuccess({
          title: "Machine updated",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleImageChange = async (event) => {
    const image = event.target.files[0];
    await uploadImageToServe(image);
  };

  const handleDragImage = async (file) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: "The number of uploaded images is greater than one.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    const image = file && file[0];

    await uploadImageToServe(image);
  };

  const uploadImageToServe = async (image) => {
    if (image && !image.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      toast({
        description: "The image format is incorrect.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    } else {
      if (checkMaxImage(image)) {
        toast({
          description: "Image must be smaller than 5MB.",
          status: "error",
          position: "bottom-right",
        });
        return false;
      }
    }
    try {
      const request = new FormData();
      request.append("file", image);
      request.append("name", image?.name || "image");
      const { data } = await postUploadImage(request);
      if (data?.code === 200) {
        setNameImage(data?.data?.name);
        setValue("image", data?.data?.url);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderBody = () => {
    // if (isCameraActive) {
    //   return (
    //     <Flex
    //       alignItems="flex-start"
    //       justifyContent="center"
    //       gap="20px"
    //       h="720px"
    //     >
    //       <iframe
    //         id="iframe-cam"
    //         title="cam"
    //         src={
    //           cameraType === STATUS.FRONT
    //             ? dataDetail?.web_link_cam_front
    //             : dataDetail?.web_link_cam_back
    //         }
    //         allowFullScreen
    //         style={{
    //           width: "720px",
    //           height: "1280px",
    //           objectFit: "cover",
    //           overflow: "hidden",
    //           transform: "scale(0.5)",
    //           marginTop: "-300px",
    //         }}
    //       />
    //       <FormProvider {...form}>
    //         <form onSubmit={handleSubmit(onSubmit)}>
    //           <Flex
    //             direction="column"
    //             alignItems="flex-start"
    //             gap="20px"
    //             ml="-50%"
    //             mt="20px"
    //           >
    //             <FormFileUpload
    //               label="Image chroma key"
    //               name="image"
    //               accept={"image/*"}
    //               textButton={nameImage}
    //               textDescription={
    //                 "Currently support .png , .jpg, less than 5mb"
    //               }
    //               onChange={(e) => handleImageChange(e)}
    //               handleDragImage={(val) => handleDragImage(val)}
    //             />
    //             <FormSelect
    //               label="Chroma key"
    //               name="chromaKey"
    //               options={CHROMA_KEY_OPTIONS}
    //             />
    //             <Flex alignItems="flex-end" gap={4}>
    //               <FormInput
    //                 type="color"
    //                 border="none"
    //                 p="0"
    //                 name="color1"
    //                 label="Color key"
    //               />
    //               <Text mb="6px">{watch("color1")}</Text>
    //             </Flex>

    //             <Flex alignItems="flex-end" gap={4}>
    //               <FormInput
    //                 type="color"
    //                 border="none"
    //                 p="0"
    //                 name="color2"
    //                 label="Color key"
    //               />
    //               <Text mb="6px">{watch("color2")}</Text>
    //             </Flex>
    //             <Flex alignItems="flex-end" gap={4}>
    //               <FormInput
    //                 type="color"
    //                 border="none"
    //                 p="0"
    //                 name="color3"
    //                 label="Color key"
    //               />
    //               <Text mb="6px">{watch("color3")}</Text>
    //             </Flex>
    //             <Flex alignItems="center" gap="20px">
    //               <Flex direction="column">
    //                 <FormInput
    //                   type="number"
    //                   min={0}
    //                   max={1}
    //                   step={0.1}
    //                   name="start_coords_x"
    //                   label="Start coords X"
    //                   w="80px"
    //                 />
    //                 <FormInput
    //                   type="number"
    //                   min={0}
    //                   max={1}
    //                   step={0.1}
    //                   name="start_coords_y"
    //                   label="Start coords Y"
    //                   w="80px"
    //                 />
    //               </Flex>
    //               <Flex direction="column">
    //                 <FormInput
    //                   type="number"
    //                   min={0}
    //                   max={1}
    //                   step={0.1}
    //                   name="end_coords_x"
    //                   label="End coords X"
    //                   w="80px"
    //                 />
    //                 <FormInput
    //                   type="number"
    //                   min={0}
    //                   max={1}
    //                   step={0.1}
    //                   name="end_coords_y"
    //                   label="End coords Y"
    //                   w="80px"
    //                 />
    //               </Flex>
    //             </Flex>
    //             <Flex alignItems="flex-end" gap={4}>
    //               <ButtonType
    //                 onClick={() => {
    //                   viewCam();
    //                 }}
    //               >
    //                 View
    //               </ButtonType>
    //               <ButtonType type="submit">Submit</ButtonType>
    //             </Flex>
    //           </Flex>
    //         </form>
    //       </FormProvider>
    //     </Flex>
    //   );
    // } else {
    //   return (
    //     <Flex
    //       direction="column"
    //       alignItems="center"
    //       justifyContent="center"
    //       width="296px"
    //       height="333px"
    //       backgroundColor="#E1E1E1"
    //       borderRadius="20px"
    //       color="#475266"
    //       m="20px auto"
    //     >
    //       <Box position="relative">
    //         <Icon
    //           fontSize="30px"
    //           position="absolute"
    //           top="22px"
    //           left="22px"
    //           as={IconXCamera}
    //         />
    //         <Icon fontSize="100px" as={IconCamera} />
    //       </Box>
    //       <Text fontWeight="700" fontSize="20px">
    //         Sorry, Camera is Missing
    //       </Text>
    //     </Flex>
    //   );
    // }
    return (
      <Flex
        alignItems="flex-start"
        justifyContent="center"
        gap="20px"
        h="720px"
      >
        <iframe
          id="iframe-cam"
          title="cam"
          src={
            cameraType === STATUS.FRONT
              ? dataDetail?.web_link_cam_front
              : dataDetail?.web_link_cam_back
          }
          allowFullScreen
          style={{
            width: "720px",
            height: "1280px",
            objectFit: "cover",
            overflow: "hidden",
            transform: "scale(0.5)",
            marginTop: "-300px",
          }}
        />
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex
              direction="column"
              alignItems="flex-start"
              gap="20px"
              ml="-50%"
              mt="20px"
            >
              <FormFileUpload
                label="Image chroma key"
                name="image"
                accept={"image/*"}
                textButton={nameImage}
                textDescription={"Currently support .png , .jpg, less than 5mb"}
                onChange={(e) => handleImageChange(e)}
                handleDragImage={(val) => handleDragImage(val)}
              />
              <FormSelect
                label="Chroma key"
                name="chromaKey"
                options={CHROMA_KEY_OPTIONS}
              />
              <Flex alignItems="flex-end" gap={4}>
                <FormInput
                  type="color"
                  border="none"
                  p="0"
                  name="color1"
                  label="Color key"
                />
                <Text mb="6px">{watch("color1")}</Text>
              </Flex>

              <Flex alignItems="flex-end" gap={4}>
                <FormInput
                  type="color"
                  border="none"
                  p="0"
                  name="color2"
                  label="Color key"
                />
                <Text mb="6px">{watch("color2")}</Text>
              </Flex>
              <Flex alignItems="flex-end" gap={4}>
                <FormInput
                  type="color"
                  border="none"
                  p="0"
                  name="color3"
                  label="Color key"
                />
                <Text mb="6px">{watch("color3")}</Text>
              </Flex>
              <Flex alignItems="flex-end" gap={2}>
                <FormSlider
                  border="none"
                  name="similarity"
                  label="Similarity"
                  min={0}
                  max={1}
                  step={0.001}
                  minWidth="100px"
                />
                <Text mb="6px">{watch("similarity")}</Text>
                <FormSlider
                  border="none"
                  name="smoothness"
                  label="Smoothness"
                  min={0}
                  max={1}
                  step={0.001}
                  minWidth="100px"
                />
                <Text mb="6px">{watch("smoothness")}</Text>
                <FormSlider
                  border="none"
                  name="spill"
                  label="Spill"
                  min={0}
                  max={1}
                  step={0.001}
                  minWidth="100px"
                />
                <Text mb="6px">{watch("spill")}</Text>
              </Flex>
              {/* <Flex alignItems="center" gap="20px">
                <Flex direction="column">
                  <FormInput
                    type="number"
                    min={0}
                    max={1}
                    step={0.1}
                    name="start_coords_x"
                    label="Start coords X"
                    w="80px"
                  />
                  <FormInput
                    type="number"
                    min={0}
                    max={1}
                    step={0.1}
                    name="start_coords_y"
                    label="Start coords Y"
                    w="80px"
                  />
                </Flex>
                <Flex direction="column">
                  <FormInput
                    type="number"
                    min={0}
                    max={1}
                    step={0.1}
                    name="end_coords_x"
                    label="End coords X"
                    w="80px"
                  />
                  <FormInput
                    type="number"
                    min={0}
                    max={1}
                    step={0.1}
                    name="end_coords_y"
                    label="End coords Y"
                    w="80px"
                  />
                </Flex>
              </Flex> */}
              <Flex alignItems="flex-end" gap={4}>
                <ButtonType
                  onClick={() => {
                    viewCam();
                  }}
                >
                  View
                </ButtonType>
                <ButtonType type="submit">Submit</ButtonType>
              </Flex>
            </Flex>
          </form>
        </FormProvider>
      </Flex>
    );
  };

  return (
    <>
      <ModalBase
        maxWContent="1400px"
        isOpen={isOpen}
        onClose={onClose}
        showBtn={false}
        titleHeader={`${cameraType} CAMERA - ${dataDetail?.mac?.slice(-4)}`}
      >
        {renderBody()}
      </ModalBase>
    </>
  );
}
