import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getGamePlayType } from "api/gameplayType.api";
import {
  changeDirectionMachine,
  changeStatusMachineSetting,
  getMachineSettings,
} from "api/machine.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import MachineRow from "components/Tables/MachineRow";
import { STATUS } from "constants/constants";
import { useCallback, useEffect, useState } from "react";
import ActionMachineSetting from "./action";
import MachineCreateAndUpdate from "./components/createAndUpdate";
import MachineDetail from "./components/detail";
import ConditionMachine from "./condition";
import { getGameMode } from "api/gameMode.api";

export default function MachineSettings() {
  const [isLoading, setIsLoading] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);

  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const toast = useToast();
  const [machines, setMachines] = useState([]);
  const [listGamePlayType, setListGamePlayType] = useState([]);
  const [gamesModeOptions, setGamesModeOptions] = useState([]);

  const labels = [
    "Machine ID",
    "Image",
    "State",
    "Direction",
    "Booth Type",
    "Gameplay Type",
    "Dedicated To",
    "Last Modified At",
    "Last Modified By",
    "Created At",
    "Created By",
    "",
  ];
  const textColor = useColorModeValue("#000000", "white");

  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSetDataDetail = (data) => {
    setDataDetail(data);
    onOpenDetail();
  };

  const handleClose = () => {
    onCloseDetail();
    onClose();
    setDataDetail(null);
  };

  const handleOpenCreateOrUpdate = (data = null) => {
    setDataDetail(data);
    onOpen();
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    handleClose();
    setDataDetail(null);
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  const getListMachine = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        setCheckedIds([]);
        const { data } = await getMachineSettings({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setMachines(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const getListGamePlayType = async () => {
    try {
      const res = await getGamePlayType();
      if (res?.data?.success) {
        const listType = res?.data?.data?.records?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));
        setListGamePlayType(listType);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const switchStatus = async (machine) => {
    const switchStatus = {
      id: machine?.id,
      status:
        machine?.status === STATUS.ACTIVE ? STATUS.NO_ACTIVE : STATUS.ACTIVE,
    };
    return changeStatusMachineSetting(switchStatus);
  };

  const handleSwitchStatus = async (machine) => {
    try {
      setIsLoading(true);
      const res = await switchStatus(machine);
      if (res?.data?.success) {
        getListMachine();
        toast({
          title: "Update success.",
          description: "Update success.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleSwitchListStatus = useCallback(async () => {
    try {
      setIsLoading(true);
      const promise = checkedIds?.map((machine) => {
        return switchStatus(machine);
      });
      const res = await Promise.all(promise);
      if (res && res[0]?.data?.success) {
        getListMachine();
        setCheckedIds([]);
        toast({
          title: "Update success.",
          description: "Update success.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }, [checkedIds]);

  const getListGame = async () => {
    try {
      const { data } = await getGameMode({
        page: 1,
        limit: 999999,
      });
      if (data?.success) {
        const res = data?.data;
        const options = res?.records?.map((item) => ({
          label: item?.mode_name,
          value: item?.id,
        }));
        setGamesModeOptions(options);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const switchDirection = async (machine) => {
    const switchDirection = {
      id: machine?.id,
      direction:
        machine?.direction === STATUS.LEFT ? STATUS.RIGHT : STATUS.LEFT,
    };
    return changeDirectionMachine(switchDirection);
  };

  const handleSwitchListDirection = useCallback(async () => {
    try {
      setIsLoading(true);
      const promise = checkedIds?.map((machine) => {
        return switchDirection(machine?.crane_machine);
      });
      const res = await Promise.all(promise);
      if (res && res[0]?.data?.success) {
        getListMachine();
        toast({
          title: "Update success.",
          description: "Update success.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }, [checkedIds]);

  useEffect(() => {
    getListGamePlayType();
    getListGame();
  }, []);

  useEffect(() => {
    getListMachine();
  }, [getListMachine]);

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(machines);
    } else {
      setCheckedIds([]);
    }
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex
            w="100%"
            gap={4}
            direction={{
              base: "column",
              md: "row",
            }}
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignItems="flex-start"
          >
            <Text
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              whiteSpace="nowrap"
            >
              Machine Settings
            </Text>
            <ActionMachineSetting
              handleOpenCreateOrUpdate={handleOpenCreateOrUpdate}
              checkedIds={checkedIds}
              setCheckedIds={setCheckedIds}
              setParams={setParams}
              params={params}
              handleSwitchListStatus={handleSwitchListStatus}
              handleSwitchListDirection={handleSwitchListDirection}
            />
          </Flex>
        </CardHeader>
        <CardHeader px="42px" mb="32px">
          <ConditionMachine
            isLoading={isLoading}
            setParams={setParams}
            params={params}
            listGamePlayType={listGamePlayType}
          />
        </CardHeader>
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck
            isChecked={
              machines?.length > 0 && checkedIds.length === machines?.length
            }
            onChangeChecked={onChangeChecked}
            dataRow={machines}
          >
            <>
              {machines?.map((item) => {
                return (
                  <MachineRow
                    key={item?.id}
                    setCheckedIds={setCheckedIds}
                    checkedIds={checkedIds}
                    handleSetDataDetail={() => {
                      handleSetDataDetail(item);
                    }}
                    handleOpenCreateOrUpdate={() => {
                      handleOpenCreateOrUpdate(item);
                    }}
                    listGamePlayType={listGamePlayType}
                    handleSwitchStatus={() => {
                      handleSwitchStatus(item);
                    }}
                    machine={item}
                    gamesModeOptions={gamesModeOptions}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
      <MachineDetail
        isOpen={isOpenDetail}
        onClose={handleClose}
        dataDetail={dataDetail}
        listGamePlayType={listGamePlayType}
        gamesModeOptions={gamesModeOptions}
      />
      {isOpen && (
        <MachineCreateAndUpdate
          isOpen={isOpen}
          onClose={handleClose}
          gamesModeOptions={gamesModeOptions}
          dataDetail={dataDetail}
          listGamePlayType={listGamePlayType}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
        />
      )}
    </Flex>
  );
}
